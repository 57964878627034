<template>
  <span v-if="formattedDate">
    {{ formattedDate }}
  </span>
  <span v-else>
    {{ $t('game-unknown') }}
  </span>
</template>

<script lang="ts">
const options = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
}

export default {
  name: 'global-date',
  props: {
    date: {
      type: Number,
      default () {
        return 0
      }
    },
    type: {
      type: String,
      default () {
        return ''
      }
    }
  },
  setup (props) {
    let value: any = ref(new Date(props.date))
    value.value = value.value instanceof Date && !isNaN(value.value.getTime()) ? value.value : null
    const formattedDate = ref(value.value ? props.type === 'YYYYMMDD' ? value.value.toLocaleDateString("ko-KR", options) : value.value.toLocaleString() : null)

    // 메모리 비우기
    function restore () {
      value.value = null
      formattedDate.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    return {
      formattedDate
    }
  }
}
</script>